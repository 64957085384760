import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import './forms-style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    background: '#fff',
    borderRadius: 15,
    border: 'solid 3px #dddddd',
    paddingTop: 10,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    width: 300,
    margin: '20px auto',
    '& > *': {
      margin: '20px auto',
      width: '80%',
    },
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#2d4147',
      },
      '&:hover fieldset': {
        borderColor: '#22b24c',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },}
));

const buttonStyles = makeStyles((theme) => ({
  root: {
    textAlign:'center',
  },
  button: {
    marginTop: 20,
    width: '80%',
    height: 40,
    background: '#22b24c',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#fa4900',
      color: '#fff',
    },
}}));

export default function Forms(props) {
  const classes = useStyles();
  const buttonClasses = buttonStyles();

  const [state,setState] = useState(props.prevAnswers ? props.prevAnswers : new Array(props.questionData.numFields).fill(''));
  const [err,setErr] = useState(false);

  useEffect(()=>{},[state]);

  const regexCompliant = () =>{
        for (let i = 0; i < state.length; i++)
        {
          let regex = new RegExp(props.questionData.fields[i].regex);
          if(!regex.test(state[i]))
            return false;
        }
        return true; 
  }

  const toggleError = (bool) => {
    setErr(bool);
  }

  const isEmpty = (value) => {
    if(value === '')
      return true;
    return false;
  }

  const handleChange = (event,key) => {
    let newState = [...state];
    newState[key] = event.target.value;
    setState(newState);
  };

  const handleClick = ()=>{
    const backFlag = props.prevAnswers ? 1 : 0;
    let temp = {};
    for (let i = 0; i < props.questionData.numFields;i++)
    {
      if(isEmpty(state[i])) {
        toggleError(true);
        return;
      }
      else {
        toggleError(false);
        temp[props.questionData.fields[i].json] = state[i];
      }
    }
    props.storeFieldData(temp,backFlag);
    setState(null);
  }

  const keyPress = (e) => {
    if (e.keyCode == 13)
    {
      e.preventDefault();
      handleClick();
    }
  }

  return (<div className='container'>
    <h2>{props.questionData.question}</h2>
        {err ? <p className='error-p'>{props.questionData.fieldError}</p> : <></>}
    <form className={classes.root} noValidate autoComplete="off">
      {
          props.questionData.fields.map((field)=>{
            if(field.type==='text')
            {
              return(<div key={field.key} >
                <TextField
                  fullWidth
                  label={field.fieldText} 
                  variant="outlined" 
                  value={state[field.key]}
                  onChange={(event)=>handleChange(event,field.key)}
                  onKeyDown={keyPress}
                  keyboardType={field.keyboardType}
                  />
              </div>)
            }
            else if (field.type==='selection')
            {
              return(
                <div key={field.key} >
                  <TextField
                    fullWidth
                    select 
                    label={field.fieldText} 
                    variant="outlined" 
                    key={field.key} 
                    value={state[field.key]}
                    onChange={(event)=>handleChange(event,field.key)}
                    onKeyDown={keyPress}
                    >
                    {
                      field.selections.map((selection)=>(
                        <MenuItem key={selection} value={selection}>
                          {selection}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              )
            }
            else if(field.type==='numericSelection')
            {
              let selections = [];
                for (let i = field.rangeMin; i <= field.RangeMax;i++){
                  selections.push(i);
                }
              if(field.reverse)
                selections = selections.reverse();
              return(<div key={field.key} >
                  <TextField 
                    fullWidth
                    select label={field.fieldText} 
                    variant="outlined" key={field.key} 
                    value={state[field.key]}
                    onChange={(event)=>handleChange(event,field.key)}
                    onKeyDown={keyPress}
                    >
                    {
                      selections.map((selection)=>(
                        <MenuItem key={selection} value={selection}>
                            {selection}
                        </MenuItem>
                      ))
                    }
                  </TextField>
              </div>)
            }
            else
            {
              //totally ignore anything else for now
            }
          })
      }
    <div className={buttonClasses.root}>
      <Button
        className={buttonClasses.button}
        variant="contained" 
        color="default"
        onClick={handleClick}
        >
        {props.questionData.buttonText}
      </Button>
    </div>
    </form>
    </div>
  );
}

