const reviews =[
    {
        Author: "John K.",
        Testimonial:"I would say this was most easiest and comfortable company to use for my recent refinance." +
            "  My loan officer Alex was amazing because he would always check up on me and guided me through every" +
            " single step without any pressure.  Then the loan processor Kristine made it even more easier because" +
            " she was able to do things quickly and in a prompt manner.  When there was one issue with one of documents," +
            " she even had one of her managers involved to expedite fixing everything.  They all worked so well as a team " +
            "and makes you feel like you're getting your money's worth for sure."
    },
    {
        Author: "Al S.",
        Testimonial:"Having retired from the mortgage business, I know what lender service should be. " +
            " Albert gets a 5-Star rating.  He was prompt in keeping the process moving, in keeping me " +
            "advised of loan status, in working with his team to minimize my fees and providing information" +
            " I needed.  Keep up the good work Albert"
    },
    {
        Author: "Sue L.",
        Testimonial:"We just refinanced for house with Lending Bridge. I have to say this was the easiest and fastest refi we have ever experienced." +
            " Joseph the loan officer was our first contact. He helped make it possible and Albert, was the processor who we worked with closely. He" +
            " cleared the way for all the paperwork we did not have to worry about, and kept us updated on the progress of our loan. They are very " +
            "thorough and we are very pleased.I would highly recommend Lending Bridge,"
    },
    {
        Author: "Armine K",
        Testimonial:"Todd and Albert did a great job helping us with our refinance. We had a special situation " +
            "but they were able to maneuver through it and get us qualified with the super loan rate.\n" +
            "I’m very pleased with their service. There will be my to go for my next refinance /purchase ."
    },
    {
        Author: "April A.",
        Testimonial:"Great company for a refinance! The 1st person I spoke with was Todd. He took the time to answer " +
            "all my questions and wasn’t pushy like most companies! Then my closing agent Kristine was super helpful too" +
            ", and communicated each step of the way. As an added bonus I didn’t feel like I had to jump thru hoops and" +
            " share months and months of my personal finance info."
    },
    {
        Author: "cshaynerun1",
        Testimonial:"My loan officer Alex and processor Albert did an excellent job on my refinance. They had great follow" +
            " through and did what they said they would. They minimized excess paperwork and did as much of the work so" +
            " I wouldn't have to."
    },
    {
        Author: "Mike B.",
        Testimonial:"Lending Bridge was professional from the start to finish in completing our refinance. We just" +
            " signed and closed the documents and are looking forward to a $120 monthly saving which will allow us to " +
            "easily recover the cost of the refinance in a couple of years. Loan officer (Joseph) and Processor (Albert)" +
            " were very helpful and always followed up with us. In fact, it was Albert and Joseph's last follow up call" +
            " which allowed us to insure that our Deed recording does not change to prevent our property from being" +
            " reassessed by the local County Tax Assessor which would would have caused us to pay more taxes after " +
            "refinancing. Joseph came thru and was able to fix the situation and get us to close the loan on time.\n" +
            "Thank you Lending Bridge and staff (Joseph and Albert)."
    },
    {
        Author: "Aris T.",
        Testimonial:"I’m very pleased and happy with Todd, the loan officer, and Kristine, the processor. They" +
            " were great. At first Todd was very on point and clear in regards to the our refinance need and whatever" +
            " he promised he was able to deliver unlike other lenders I’ve dealth with in the past. Then my file got" +
            " passed to Kristine. She was on top of it. Always kept us updated and guid us through the entire process." +
            " Highly recommend.\n" +
            "Thank  you"
    },
    {
        Author: "Mario G.",
        Testimonial:"Our experience with Lendingbridge was a great one. We had Todd and Albert helping us along the" +
            " way. They made the experience with refinancing easy. Albert always kept informed with any updates or " +
            "changes along the way.\n" +
            "Even after closing, Todd and Albert still helped us out with any questions we had. I give these guys " +
            "5 stars and would recommend refinancing with them if anyone asked.\n" +
            "Thank you guys for making this transition so easy."
    },
    {
        Author: "Queenie L.",
        Testimonial:"I would give 7 stars if allowed to.  Thank you, Joseph and Albert, for your professionalism.  " +
            "Thanks for your help and efforts to bring my need to the finish line.  We all knew that there are so many" +
            " scams out there. I love the way how you two introduced yourselves, again, after our initial phone call," +
            " along with information of your company and your licenses to proof that I was talking to real and good " +
            "people, and followed up with every single step as we moved forward.  Very satisfied with the service." +
            "  I have recommended you and your company to friends, would tell everyone I know who needs your help. " +
            " Thank you!"
    },
    {
        Author: "Mary S.",
        Testimonial:"My experience with working with Alex and his processor Kristine was very professional. they " +
            "were able to answer and resolve all my questions and concerns. I would highly recommend Alex & Kristine" +
            " from the LendingBridge to my family and friends."
    },
];

export default reviews;