import React,{useState,useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {questionSet} from './Data';
import Forms from '../Forms';
import Slider from '../Slider';
import IconGrid from '../IconGrid';
import ButtonGrid from '../ButtonGrid';
import NoThanks from '../NoThanks';
import {submitQuote} from '../../services/submitQuote'
import Button from '@material-ui/core/Button';
import './quote-master-style.css';
import {makeStyles} from "@material-ui/core/styles";

const buttonStyles = makeStyles((theme) => ({
  root: {
    textAlign:'center',
  },
  button: {
    marginTop: 20,
    height: 40,
    width: 100,
    backgroundColor: '#222e5f',
    color: '#aaaaaa',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#22b24c',
    },
    '&:active':{
      backgroundColor: '#fff',
      color: '#22b24c',
    }
  }}));

const QuoteMaster = () => {
  const [i,setI] = useState(0);
  const [QNA,setQNA] = useState({});

  const buttonClasses = buttonStyles();
  useEffect(()=>{},[QNA,i]);

  const qNum = () => {
    switch(i)
    {
      case 7:
        if(QNA['secondMortgage'] === 'No')
          setI(9);
        break;
      case 12:
        if(QNA['military'] === 'No')
          setI(13);
        break;
      case 14:
        if(QNA['bankruptcyForeclosure'] === 'No')
          setI(16);
        else if (QNA['bankruptcyForeclosure'] === 'Foreclosure')
          setI(15);
        break;
      case 15: 
        if(QNA['bankruptcyForeclosure'] === 'Bankruptcy')
          setI(16);
        break;
      default:
        break;
    }
    return i;
  }

  const backButton = () =>{
    switch(i)
    {
      case 9:
        if(QNA['secondMortgage'] === 'No')
          setI(6);
        else
          setI(i - 1);
          break;
      case 13:
        if(QNA['military'] === 'No')
          setI(11);
        else
          setI(i - 1);
        break;
      case 15: 
        if(QNA['bankruptcyForeclosure'] === 'Foreclosure')
          setI(13);
        else
          setI(i - 1);
        break;
      case 16:
        if(QNA['bankruptcyForeclosure'] === 'No')
          setI(13);
        else if (QNA['bankruptcyForeclosure'] === 'Bankruptcy')
          setI(14);
        else
          setI(i - 1);
        break;
      default:
        setI(i - 1);
        break;
    }
  }



  const storeButtonData = (question,answer,backFlag) => {
    let temp = {...QNA}; 
    temp[question]=answer;
    setQNA(temp);
    setI(i + 1);
  }

  const storeFieldData = (data,backFlag) => {
    let temp = {...QNA,...data}; 
    setQNA(temp);
    setI(i + 1);
  }

  const genFieldPrevAnswer = (question) => {
    let temp = [];
    question.fields.map((field)=>{
      if (QNA[field.json])
        temp.push(QNA[field.json]);
      else 
        temp.push('');
    })
    return temp;
  }

  const storeSliderData = (question,answer,backFlag) => {
    let temp = {...QNA}; 
    temp[question]=answer;
    setQNA(temp);
    setI(i + 1);
  }

  const injectSliderData = (question) =>{
    if (question.id===5)
    {
      question['limitMin'] = question.increment;
      question['limitMax'] = Number(QNA['propertyValue']);
      question['textMin'] = question['limitMin'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or Less';
      question['textMax'] = question['limitMax'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or More';
    }
    if(question.id===7)
    {
      question['limitMin'] = question.increment;
      question['limitMax'] = Number(QNA['propertyValue']-QNA['firstMortgageBalance']);
      question['textMin'] = question['limitMin'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or Less';
      question['textMax'] = question['limitMax'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or More';
    }
    if(question.id===8)
    {
      question['limitMin'] = question.increment;
      question['limitMax'] = Number(QNA['propertyValue']-QNA['firstMortgageBalance']-QNA['secondMortgageBalance']);
      question['textMin'] = question['limitMin'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or Less';
      question['textMax'] = question['limitMax'].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + ' or More';
    }
    return question;
  }

  const displayQuestion = (question) =>{
    if (QNA["firstMortgageBalance"] != undefined && Number(QNA["firstMortgageBalance"]) < 150000){
      return <NoThanks/>
    }
    if (typeof question==='undefined')
    {
      submitQuote(QNA);
      return <Redirect
          to="/confirmation" />;
    }
    if(question.type==='icons')
      {
        return <IconGrid
            questionData={question}
            storeIconData={storeButtonData}
            prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
            key={question.id}/>;
      }
    else if(question.type==='text')
      {
        return <ButtonGrid
            questionData={question}
            storeButtonData={storeButtonData}
            prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
            key={question.id}/>;
      }
    else if(question.type==='field')
      {
        return <Forms
            questionData={question}
            storeFieldData={storeFieldData}
            prevAnswers={genFieldPrevAnswer(question)}
            key={question.id}/>;
      }
    else if(question.type==='slider')
      {
        question = injectSliderData(question);
        return <Slider
          questionData={question}
          storeSliderData={storeSliderData}
          prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
          key={question.id}/>;
      }
    else
    {
      //TODO: add default case
    }
  }

  return (
    <div className='mainContainer'>
        {displayQuestion(questionSet[qNum()])}
        {i > 0 && !(QNA["firstMortgageBalance"] != undefined && Number(QNA["firstMortgageBalance"]) < 150000)
        ?
        <div className={buttonClasses.root}>
          <Button
              className={buttonClasses.button}
              variant="contained"
              color="default"
              onClick={backButton}
          >BACK</Button>
        </div>
        :
        <div>
        </div>}
    </div>
  );
};

export default QuoteMaster; 
