import axios from 'axios'

const api = axios.create({
    baseURL: 'https://quotemeserver.herokuapp.com/shortQuotes'
})

export const getData = () =>{
    api.get('/').then(res => {
        console.log(res.data);
    })
}

export const submitShortQuote = async (object) => {
    let res = await api.post(
        '/',
        JSON.stringify(object),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
}