  import React, {useState} from 'react';
  import './icon-grid-style.css';



  export default function IconGrid(props) {
    const [touch,setTouch] = useState(-1);
    const handleClick = (question,answer)=>{
      props.storeIconData(question,answer);
    };
    return (
      <div className='icons-container'>
        <h2>{props.questionData.question}</h2>
        <div className='card-container'>
          {
            props.questionData.options.map((option,index)=>(            
            <div className={touch === index ? 'card card-touch' : 'card'} onClick={()=>handleClick(props.questionData.json,option.text)} key={index} onTouchStart={()=>setTouch(index)} onTouchEnd={()=>setTouch(-1)}>
              {option.iconPath ? <div className='card-img' style={{backgroundImage: `url(${option.iconPath})`}}></div> : <div></div>}
            <p className='card-p'>{option.text}</p>
        </div>))
          }
        </div>
      </div>
    );
  }
