import React from 'react';
import Footer from '../components/Footer';
import QuoteMaster from '../components/QuoteMaster';
import logo from '../images/ESSENTIAL/LBlogo.svg'

import './style.css'

function Home() {
  return (
    <>
      <img className='logo' src={logo} alt=''/>
      <QuoteMaster/>
      <Footer />
    </>
  );
}

export default Home;
