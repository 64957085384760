import React from 'react';
import './App.css';
import ReactPixel from 'react-facebook-pixel';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import Sandbox from './pages/sandbox';
import Confirmation from './pages/confirmation';
import ShortForm from './pages/shortform';

function App() {
  ReactPixel.init('504862907229501');
  ReactPixel.pageView();
  return (
    <div>
    <Router>
      <Switch>
        <Route path='/' component={ShortForm} exact />
        <Route path='/sandbox' component={Sandbox}/>
        <Route path='/confirmation' component={Confirmation}/>
        <Route path='/s' component={Home}/>
      </Switch>
    </Router>
    </div>
  );
}

export default App;
