import React from 'react'
import './nothanks-styles.css'

const NoThanks = () =>{
    return <div className='nothanks-container'>
        <div>
        <h2>No Offers Available</h2>
        <p><b>We apologize for the inconvenience but there are currently no proposed benefits for this application. 
            If you think this message is in error, please give us a call and we would be more than 
            happy to talk to you.</b></p>
        </div>
    </div>
}

export default NoThanks;