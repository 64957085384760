export const questionSet = [
    {
        id: 0,
        json: "propertyType",
        type: 'icons',
        question: 'Great! What type of property are you refinancing?',
        numOptions: 5,
        options: [
            {
                key: 0,
                text: 'Single Family Home',
                iconPath: require('../../images/SVG/LONG/0-1.svg')
            },
            {
                key: 1,
                text: "Town Home",
                iconPath: require('../../images/SVG/LONG/0-2.svg')
            },
            {
                key: 2,
                text: 'Condominium',
                iconPath: require('../../images/SVG/LONG/0-3.svg')
            },
            {
                key: 3,
                text: 'Multi Family Home',
                iconPath: require('../../images/SVG/LONG/0-4.svg')
            },
            {
                key: 4,
                text: 'Manufactured or Mobile Home',
                iconPath: require('../../images/SVG/LONG/0-5.svg')
            },
        ],
    },
    {
        id: 1,
        json:"propertyUse",
        type: 'icons',
        question: 'How will this property be used?',
        numOptions: 3,
        options: [
            {
                key: 0,
                text: 'Primary Home',
                iconPath: require('../../images/SVG/LONG/1-1.svg')
            },
            {
                key: 1,
                text: 'Secondary Home',
                iconPath: require('../../images/SVG/LONG/1-2.svg')
            },
            {
                key: 2,
                text: 'Rental Property ',
                iconPath: require('../../images/SVG/LONG/1-3.svg')
            },
        ],
    },
    {
        id: 2,
        json: "refiReason",
        type: 'text',
        question: 'Why do you want to refinance?',
        numOptions: 5,
        options: [
            'Drop my rate',
            'Pay off my mortgage ASAP',
            'Take cash out of home',
            'Change my ARM loan to Fixed',
            'Browse current mortgage rates'
        ],
    },
    {
        id: 3,
        type: 'field',
        numFields: 1,
        question: 'ZIP code of the Property',
        fields: [
            {
            key: 0,
            json: "zipCode",
            type: 'text',
            keyboardType: 'number-pad',
            fieldText: 'Enter Zip',
            regex: '[0-9]{5}'
            }
        ],
        fieldError: 'Please enter a valid property zip code.',
        buttonText: 'Continue'
    },
    {
        id: 4,
        json: "propertyValue",
        type: 'slider',
        unit: "$",
        question: 'Please estimate the value of the property',
        limitMin: 100000,
        textMin: "$100,000 or Less",
        limitMax: 2000000,
        textMax: '$2,000,000 or More',
        increment: 50000,
        buttonText: 'Continue'
    },
    {
        id: 5,
        json: "firstMortgageBalance",
        type: 'slider',
        unit: "$",
        question: 'What is the remaining 1st mortgage balance?',
        increment: 50000,
        buttonText: 'Continue'
    },
    {
        id: 6,
        json: "secondMortgage",
        type: 'icons',
        question: 'Do you have a second mortgage?',
        numOptions: 2,
        options: [
            {
                key: 0,
                text: 'Yes',
                iconPath: require('../../images/SVG/LONG/yes.svg')
            },
            {
                key: 1,
                text: 'No',
                iconPath: require('../../images/SVG/LONG/no.svg')
            },
        ],
    },
    {
        id: 7,
        json: "secondMortgageBalance",
        type: 'slider',
        unit: "$",
        question: 'What is the remaining balance on the 2nd mortgage?',
        increment: 50000,
        buttonText: 'Continue'
    },
    {
        id: 8,
        json: "additionalCash",
        type: 'slider',
        unit: "$",
        question: 'Would you like to borrow additional cash?',
        increment: 50000,
        buttonText: 'Continue'
    },
    {
        id: 9,
        json: "creditScore",
        type: 'icons',
        question: 'Estimate your credit score',
        numOptions: 4,
        options: [
            {
                key: 0,
                text: 'Excellent, above 720',
                iconPath: require('../../images/SVG/LONG/9-1.svg')
            },
            {
                key: 1,
                text: 'Good, 680-719',
                iconPath: require('../../images/SVG/LONG/9-2.svg')
            },
            {
                key: 2,
                text: 'Fair, 640-679',
                iconPath: require('../../images/SVG/LONG/9-3.svg')
            },
            {
                key: 3,
                text: 'Poor, below 639',
                iconPath: require('../../images/SVG/LONG/9-4.svg')
            },
        ],
    },
    {
        id: 10,
        type: 'field',
        numFields: 3,
        question: 'When were you born?',
        fields: [
            {
                key: 0,
                json: 'birthMonth',
                type: 'selection',
                fieldText: 'Month',
                selections:
                [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ]
            },
            {
                key: 1,
                json: 'birthDay',
                type: 'numericSelection',
                fieldText: 'Day',
                rangeMin: 1,
                RangeMax: 31
            },
            {
                key: 2,
                json: 'birthYear',
                type: 'numericSelection',
                fieldText: 'Year',
                reverse: 'yes',
                rangeMin: 1922,
                RangeMax: 2003
            },
        ],
        fieldError: 'Please enter a valid date.',
        buttonText: 'Continue'
    },
    {
        id: 11,
        json: "military",
        type: 'icons',
        question: 'Have you or your spouse served in the military?',
        numOptions: 2,
        options: [
            {
                key: 0,
                text: 'Yes',
                iconPath: require('../../images/SVG/LONG/yes.svg')
            },
            {
                key: 1,
                text: 'No',
                iconPath: require('../../images/SVG/LONG/no.svg')
            },
        ],
    },
    {
        id: 12,
        json: "vaLoan",
        type: 'icons',
        question: 'Do you currently have a VA loan?',
        numOptions: 2,
        options: [
            {
                key: 0,
                text: 'Yes',
                iconPath: require('../../images/SVG/LONG/yes.svg')
            },
            {
                key: 1,
                text: 'No',
                iconPath: require('../../images/SVG/LONG/no.svg')
            },
        ],
    },
    {
        id: 13,
        json: 'bankruptcyForeclosure',
        type: 'icons',
        question: 'Have you had a bankruptcy or foreclosure in the last 7 years?',
        numOptions: 4,
        options: [
            {
                key: 0,
                text: 'No',
                iconPath: require('../../images/SVG/LONG/9-1.svg')
            },
            {
                key: 1,
                text: 'Bankruptcy',
                iconPath: require('../../images/SVG/LONG/12-1.svg')
            },
            {
                key: 2,
                text: 'Foreclosure',
                iconPath: require('../../images/SVG/LONG/12-2.svg')
            },
            {
                key: 3,
                text: 'Both',
                iconPath: require('../../images/SVG/LONG/9-4.svg')
            },
        ],
    },
    {
        id: 14,
        json: 'bankruptcyDate',
        type: 'slider',
        unit: 'years',
        question: 'How long ago was the bankruptcy?',
        limitMin: 0,
        textMin: 'Last 12 Months',
        limitMax: 6,
        textMax: '6 Years or More',
        increment: 1,
        buttonText: 'Continue'
    },
    {
        id: 15,
        json: 'foreclosureDate',
        type: 'slider',
        unit: 'years',
        question: 'How long ago was the foreclosure?',
        limitMin: 0,
        textMin: 'Last 12 Months',
        limitMax: 7,
        textMax: '7 Years or More',
        increment: 1,
        buttonText: 'Continue'
    },
    {
        id: 16,
        type: 'field',
        numFields: 4,
        question: 'What is your current street addess?',
        fields: [
            {
                key: 0,
                json: 'addressStreet',
                type: 'text',
                fieldText: 'Street Address',
                keyboardType: 'ascii-capable-number-pad',
                regex: '.*'
            },
            {
                key: 1,
                json: 'addressCity',
                type: 'text',
                fieldText: 'City',
                keyboardType: 'ascii-capable',
                regex: '[A-Za-z]{2,},{1}\s?[A-Za-z]{2,}'
            },
            {
                key: 2,
                json: 'addressZipcode',
                type: 'text',
                fieldText: 'Zip Code',
                keyboardType: 'number-pad',
                regex: '[0-9]{5}'
            },
            {
                key: 3,
                json: 'addressState',
                type: 'selection',
                fieldText: 'State',
                selections:
                [
                    'Alabama',
                    'Alaska',
                    'American Samoa',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'District of Columbia',
                    'Federated States of Micronesia',
                    'Florida',
                    'Georgia',
                    'Guam',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Marshall Islands',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Northern Mariana Islands',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Palau',
                    'Pennsylvania',
                    'Puerto Rico',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virgin Island',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming']
            }
        ],
        fieldError: 'Please enter a valid addess.',
        buttonText: 'Continue'
    },
    {
        id: 17,
        type: 'field',
        numFields: 2,
        question: 'What is your name?',
        fields: [
            {
                key: 0,
                json: 'firstName',
                type: 'text',
                fieldText: 'First Name',
                regex: '^[a-zA-z\-\s]+$'
            },
            {
                key: 1,
                json: 'lastName',
                type: 'text',
                fieldText: 'Last Name',
                regex: '^[a-zA-z\-\s]+$'
            },
        ],
        fieldError: 'Please enter your full first and last name.',
        buttonText: 'Continue'
    },
    {
        id: 18,
        type: 'field',
        numFields: 1,
        question: 'What is your email?',
        fields: [
            {
                key: 0,
                json: 'email',
                type: 'text',
                fieldText: 'Email Address',
                keyboardType: 'email-address',
                icon: '',
                regex: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$'
            },

        ],
        fieldError: 'Please enter your email.',
        buttonText: 'Continue'
    },
    {
        id: 19,
        type: 'field',
        numFields: 1,
        question: 'Mobile or home phone number?',
        fields: [
            {
                key: 0,
                json: 'phone',
                type: 'text',
                fieldText: 'Phone Number',
                keyboardType: 'numbers-and-punctuation',
                icon: '',  
                regex: '^(1\-){0,1}[0-9]{3}\-[0-9]{3}\-[0-9]{4}|[0-9]{10,11}$'
            },

        ],
        fieldError: 'Please enter your phone number.',
        buttonText: 'Calculate'
    },
]

/*DATA TEMPLATE
    //Icons
    {
        id: 0,
        type: 'icons',
        question: '',
        numOptions: 0,
        options: [
            {
                text: '',
                iconPath: ''
            },
        ],
    },
    //Text
        id: 0,
        type: 'text',
        question: '?',
        numOptions: 1,
        options: [
            '!',
        ],
    },
    //Field
    {
        id: 3,
        type: 'field',
        numFields: 1,
        question: 'ZIP code of the Property',
        fields: [
            {
            type: text,
            fieldText: 'Enter Zip',
            }
        ],
        fieldError: 'Please enter a valid property zip code.',
        buttonText: 'Continue'
    },
    //FIELD SELECTORS
        {
        id: 10,
        type: 'field',
        numFields: 3,
        question: 'When were you born?',
        fields: [
            {
            type: 'selection',
            fieldText: 'Month',
            selections:
            [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ]
            },
            {
                type: 'numericSelection',
                fieldText: 'Day',
                rangeMin: 1,
                RangeMax: 31
            },
            {
                type: 'numericSelection',
                fieldText: 'Year',
                rangeMin: 1922,
                RangeMax: 2003
            },
        ],
        fieldError: 'Please enter a valid date.',
        buttonText: 'Continue'
    },
    //SLIDER
        {
        id: 0,
        type: 'slider',
        question: 'Please estimate the value of the property',
        limitMin: 80000,
        limitMax: 2000000,
        increment: 5000,
    },
*/