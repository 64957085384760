import React from 'react'
import STARS from '../../images/TESTIMONIALS/STARS.svg'

import './testimonials-style.css'

import Reviews from './TestimonialData'
import {FaPause} from 'react-icons/fa'
import {AiFillCaretRight} from 'react-icons/ai'
import {AiFillCaretLeft} from 'react-icons/ai'

const delay = 10000;

const Testimonials = () =>{
    const [index, setIndex] = React.useState(0);
    const [pause,setPause] = React.useState(false);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    function changeIndex(control) {
        if(control==='next')
            setIndex(index === Reviews.length - 1 ? 0 : index + 1)
        else if(control==='last')
            setIndex(index === 0 ? Reviews.length - 1 : index - 1)
    }

    React.useEffect(() => {
        if(!pause)
        {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === Reviews.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
        }
    }, [index]);

    React.useEffect(()=>{},[pause])

    return <div className={'testimonials-container'}>
        <h2 className={'testimonials-title'}>CUSTOMER TESTIMONIALS</h2>
            <div
            className="testimonial"
            key={index}>
            <div
                className="testimonials-container-2"
                key={index}
            >
                <p><span><img src={STARS} alt={''}/>
                            </span>{Reviews[index].Testimonial}
                    <span><h4>{`- ${Reviews[index].Author}`}</h4></span></p>

            </div>
            <div className='testimonials-control-buttons'>
                <AiFillCaretLeft size={30} onClick={()=>changeIndex('last')}/>
                <FaPause className={pause ? 'pause' : 'unpause'} onClick={()=>setPause(!pause)} size={30}/>
                <AiFillCaretRight size={30} onClick={()=>changeIndex('next')}/>
            </div>
        </div>

    </div>
}

export default Testimonials;