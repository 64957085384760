import React from 'react'
import './footer-style.css'
import Modal from '../Modal'
import PartnerSection from '../PartnersSection'
import Testimonials from "../Testimonials";

const Footer = () => {
  return (
  <div className='footer'>
    <hr></hr>
    <p className='phone'>Call us <span><a href="tel:+18007776555">800-777-6555</a></span></p>
    <p className='links'><span><Modal buttonText='Privacy Policy'/></span> | <span><Modal buttonText='Terms of Use'/></span> | <span><Modal buttonText='Disclosures'/></span></p>
    <PartnerSection/>
    <Testimonials/>
    <p className='disclosure'>
    Copyright 2021 lendingBridge™| All Rights Reserved | 221 W Alameda St.#204 Burbank CA 91502 | info@lendingbridge.com | P: 818.629.0404 | 
    F: 818.875.9375 lendingBridge, Inc. (NMLS ID: 1706052) formally known as Millennial Financial Inc. is licensed by the California Department 
    of Business Oversight under California Finance Lenders Law license #60DBO-106597 lendingBridge, Inc. (NMLS ID: 1706052 ) formally known as
    Millennial Financial, Inc. is licensed by California Department of Real Estate as real estate corporation license #02061532
    </p>
  </div>
  );
};

export default Footer;