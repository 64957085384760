import axios from 'axios'

const api = axios.create({
    baseURL: 'https://quotemeserver.herokuapp.com/quotes'
})

export const getData = () =>{
    api.get('/').then(res => {
        console.log(res.data);
    })
}

export const submitQuote = async (object) => {
    switch (object.birthMonth){
        case 'January':
            object.birthMonth = "1";
            break;
        case 'February':
            object.birthMonth = "2";
            break;
        case 'March':
            object.birthMonth = "3";
            break;
        case 'April':
            object.birthMonth = "4";
            break;
        case 'May':
            object.birthMonth = "5";
            break;
        case 'June':
            object.birthMonth = "6";
            break;
        case 'July':
            object.birthMonth = "7";
            break;
        case 'August':
            object.birthMonth = "8";
            break;
        case 'September':
            object.birthMonth = "9";
            break;
        case 'October':
            object.birthMonth = "10";
            break;
        case 'November':
            object.birthMonth = "11";
            break;
        case 'December':
            object.birthMonth = "12";
            break;
    }
    let res = await api.post(
        '/',
        JSON.stringify(object),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
}