import React,{useState,useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {questionSet} from './ShortData';
import Forms from '../Forms';
import Slider from '../Slider';
import IconGrid from '../IconGrid';
import ButtonGrid from '../ButtonGrid';
import NoThanks from '../NoThanks';
import {submitShortQuote} from '../../services/submitShortQuote';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import './quote-master-style.scss';

const buttonStyles = makeStyles((theme) => ({
  root: {
    textAlign:'center',
  },
  button: {
    marginTop: 20,
    height: 35,
    width: 110,
    backgroundColor: 'orange',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#22b24c',
    },
    '&:active':{
      backgroundColor: '#fff',
      color: '#22b24c',
    }
  }}));

const QuoteMasterShort = () => {
  const buttonClasses = buttonStyles();
  const [i,setI] = useState(0);
  const [QNA,setQNA] = useState({});

  useEffect(()=>{},[QNA,i]);

  const backButton = () =>{
    setI(i-1);
  }

  const storeButtonData = (question,answer,backFlag) => {
    let temp = {...QNA}; 
    temp[question]=answer;
    setQNA(temp);
    setI(i + 1);
  }

  const storeFieldData = (data,backFlag) => {
    let temp = {...QNA,...data}; 
    setQNA(temp);
    setI(i + 1);
  }

  const genFieldPrevAnswer = (question) => {
    let temp = [];
    question.fields.map((field)=>{
      if (QNA[field.json])
        temp.push(QNA[field.json]);
      else 
        temp.push('');
    })
    return temp;
  }

  const storeSliderData = (question,answer,backFlag) => {
    let temp = {...QNA}; 
    temp[question]=answer;
    setQNA(temp);
    setI(i + 1);
  }
  
  const displayQuestion = (question) =>{
    if(QNA['lastRefiDate']==='Less than 12 months ago')
    {
      return <NoThanks/>
    }
    if (QNA["firstMortgageBalance"] != undefined && Number(QNA["firstMortgageBalance"]) < 150000){
      return <NoThanks/>
    }
    if (typeof question==='undefined')
    {
      submitShortQuote(QNA);
      return <Redirect
          to="/confirmation" />;
    }
    if(question.type==='icons')
      {
        return <IconGrid
            questionData={question}
            storeIconData={storeButtonData}
            prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
            key={question.id}/>;
      }
    else if(question.type==='text')
      {
        return <ButtonGrid
            questionData={question}
            storeButtonData={storeButtonData}
            prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
            key={question.id}/>;
      }
    else if(question.type==='field')
      {
        return <Forms
            questionData={question}
            storeFieldData={storeFieldData}
            prevAnswers={genFieldPrevAnswer(question)}
            key={question.id}/>;
      }
    else if(question.type==='slider')
      {
        return <Slider
          questionData={question}
          storeSliderData={storeSliderData}
          prevAnswers={QNA[question.json] ? QNA[question.json] : undefined}
          key={question.id}/>;
      }
    else
    {
      //TODO: add default case
    }
  }

  const progress = () =>{
    if (i>0)
      return Math.round(100*(i/questionSet.length));
    return 0;
  }

  return (
    <div className='mainContainer'>
      <div className="container">
        <div className="progressbar-container">
          <div className="progressbar-complete" style={{width: `${progress()}%`}}>
            <div className="progressbar-liquid"></div>
          </div>
          <span className="progress">{progress()}%</span>
        </div>
      </div>
        {displayQuestion(questionSet[i])}
        {i > 0 && !(QNA["firstMortgageBalance"] != undefined && Number(QNA["firstMortgageBalance"]) < 150000)  && !(QNA['lastRefiDate']==='Less than 12 months ago')
        ?           
        <div className={buttonClasses.root}>
          <Button
              className={buttonClasses.button}
              variant="contained"
              color="default"
              onClick={backButton}
          >BACK</Button>
        </div>
        :
        <div></div>}
    </div>
  );
};

export default QuoteMasterShort; 
