import React from 'react'

import './partner-styles.css'

import UWM from '../../images/PARTNERS/uwm.png'
import QUICKEN from '../../images/PARTNERS/quicken.png'
import NMSI from '../../images/PARTNERS/nmsi.png'
import CARRINGTON from '../../images/PARTNERS/carrington.png'
import AMWEST from '../../images/PARTNERS/amwest.png'

const PartnerSection = () =>{
    return <div className="partner-section-container">
        <h2>OUR TRUSTED PARTNERS</h2>
        <img src={UWM} alt='UWM'/>
        <img src={QUICKEN} alt='QUICKEN'/>
        <img src={NMSI} alt='NMSI'/>
        <img src={CARRINGTON} alt='CARRINGTON'/>
        <img src={AMWEST} alt='AMWEST'/>
    </div>
}

export default PartnerSection;