import React from 'react';
import './button-grid-style.css'

const ButtonGrid = (props) => {
    const handleClick = (question,answer)=>{
        const backFlag = props.prevAnswers ? 1 : 0;
        props.storeButtonData(question,answer,backFlag);
      };

    return(
        <div className='button--grid--container'>
            <h2>{props.questionData.question}</h2>
            {props.questionData.options.map((option,index)=>(
                <div className='button--grid--button' onClick={()=>handleClick(props.questionData.json,option)} key={index}>
                    <p>{option}</p>
                </div>
            ))}
        </div>
    )
}

export default ButtonGrid;