import React from 'react';
import Footer from '../components/Footer';
import QuoteMasterShort from '../components/QuoteMasterShort';
import logo from '../images/ESSENTIAL/LBlogo.svg'

import './style.css'

function ShortForm() {
  return (
    <>
      <img className='logo' src={logo} alt=''/>
      <QuoteMasterShort/>
      <Footer />
    </>
  );
}

export default ShortForm;
