export const questionSet = [
    {
        id: 0,
        json: "propertyType",
        type: 'icons',
        question: 'Great! What type of property are you refinancing?',
        numOptions: 5,
        options: [
            {
                key: 0,
                text: 'Single Family Home',
                iconPath: require('../../images/SVG/SHORT/0-1.svg')
            },
            {
                key: 1,
                text: "Town Home",
                iconPath: require('../../images/SVG/SHORT/0-2.svg')
            },
            {
                key: 2,
                text: 'Condominium',
                iconPath: require('../../images/SVG/SHORT/0-3.svg')
            },
            {
                key: 3,
                text: 'Multi Family Home',
                iconPath: require('../../images/SVG/SHORT/0-4.svg')
            },
            {
                key: 4,
                text: 'Manufactured or Mobile Home',
                iconPath: require('../../images/SVG/SHORT/0-5.svg')
            },
        ],
    },
    {
        id: 1,
        json: "refiReason",
        type: 'text',
        question: 'Why do you want to refinance?',
        numOptions: 5,
        options: [
            'Drop my rate',
            'Pay off my mortgage ASAP',
            'Take cash out of home',
            'Change my ARM loan to Fixed',
            'Browse current mortgage rates'
        ],
    },
    {
        id: 2,
        json: "creditScore",
        type: 'icons',
        question: 'Estimate your credit score',
        numOptions: 4,
        options: [
            {
                key: 0,
                text: 'Excellent, above 720',
                iconPath: require('../../images/SVG/SHORT/2-1.svg')
            },
            {
                key: 1,
                text: 'Good, 680-719',
                iconPath: require('../../images/SVG/SHORT/2-2.svg')
            },
            {
                key: 2,
                text: 'Fair, 640-679',
                iconPath: require('../../images/SVG/SHORT/2-3.svg')
            },
            {
                key: 3,
                text: 'Poor, below 639',
                iconPath: require('../../images/SVG/SHORT/2-4.svg')
            },
        ],
    },
    {
        id: 3,
        json: "loanType",
        type: 'icons',
        question: 'The loan I currently hold is...',
        numOptions: 4,
        options: [
            {
                key: 0,
                text: 'Conventional',
                iconPath: require('../../images/SVG/SHORT/3-1.svg')
            },
            {
                key: 1,
                text: 'FHA/VA Loan',
                iconPath: require('../../images/SVG/SHORT/3-2.svg')
            },
            {
                key: 2,
                text: 'Private/Hard Money',
                iconPath: require('../../images/SVG/SHORT/3-3.svg')
            },
            {
                key: 3,
                text: 'Not sure',
                iconPath: require('../../images/SVG/SHORT/3-4.svg')
            },
        ],
    },
    {
        id: 4,
        json: "lastRefiDate",
        type: 'text',
        question: 'Last time I refinanced was...',
        numOptions: 4,
        options: [
            'Less than 12 months ago',
            'Within the last 1-3 years',
            '4 or more years ago',
            'I\'m not sure',
        ],
    },
    {
        id: 5,
        json: "firstMortgageBalance",
        type: 'slider',
        unit: "$",
        question: 'What is the remaining 1st mortgage balance?',
        limitMin: 100000,
        textMin: "$100,000 or Less",
        limitMax: 2000000,
        textMax: '$2,000,000 or More',
        increment: 50000,
        buttonText: 'Continue'
    },
    {
        id: 6,
        json: "covidImpact",
        type: 'text',
        question: 'COVID-19 has impacted me in the following way...',
        numOptions: 5,
        options: [
            'Things are mostly the same',
            'Reduced wages/hours at work',
            'I\'m making more money now',
            'I\'m currently unemployed',
            'Other'
        ],
    },
    {
        id: 7,
        json: "timeToTalk",
        type: 'icons',
        question: 'I prefer to talk...',
        numOptions: 4,
        options: [
            {
                key: 0,
                text: 'In The Morning',
                iconPath: require('../../images/SVG/SHORT/8-1.svg')
            },
            {
                key: 1,
                text: 'In The Afternoon',
                iconPath: require('../../images/SVG/SHORT/8-2.svg')
            },
            {
                key: 2,
                text: 'In The Evening',
                iconPath: require('../../images/SVG/SHORT/8-3.svg')
            },
            {
                key: 3,
                text: 'Anytime',
                iconPath: require('../../images/SVG/SHORT/8-4.svg')
            },
        ],
    },
    {
        id: 8,
        type: 'field',
        numFields: 2,
        question: 'What is your name?',
        fields: [
            {
                key: 0,
                json: 'firstName',
                type: 'text',
                fieldText: 'First Name',
                regex: '^[a-zA-z\-\s]+$'
            },
            {
                key: 1,
                json: 'lastName',
                type: 'text',
                fieldText: 'Last Name',
                regex: '^[a-zA-z\-\s]+$'
            },
        ],
        fieldError: 'Please enter your full first and last name.',
        buttonText: 'Continue'
    },
    {
        id: 9,
        type: 'field',
        numFields: 1,
        question: 'What is your email?',
        fields: [
            {
                key: 0,
                json: 'email',
                type: 'text',
                fieldText: 'Email Address',
                keyboardType: 'email-address',
                icon: '',
                regex: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$'
            },

        ],
        fieldError: 'Please enter your email.',
        buttonText: 'Continue'
    },
    {
        id: 10,
        type: 'field',
        numFields: 1,
        question: 'Enter phone number',
        fields: [
            {
                key: 0,
                json: 'phone',
                type: 'text',
                fieldText: 'Phone Number',
                keyboardType: 'numbers-and-punctuation',
                icon: '',  
                regex: '^(1\-){0,1}[0-9]{3}\-[0-9]{3}\-[0-9]{4}|[0-9]{10,11}$'
            },

        ],
        fieldError: 'Please enter your phone number',
        buttonText: 'Calculate'
    },
]