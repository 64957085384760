import React, {useState} from 'react';
import './slider-style.css'
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";

const buttonStyles = makeStyles((theme) => ({
    root: {
      textAlign:'center',
      clear: 'both',
    },
    button: {
      marginTop: 20,
      height: 50,
      width: 150,
      backgroundColor: 'limegreen',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      '&:hover': {
        backgroundColor: '#fa4900',
      },
      '&:active':{
        backgroundColor: '#fa4900',
      }
}}));

const Slider = (props) =>{
    const buttonClasses = buttonStyles();
    const [value,setValue] = useState(props.prevAnswers ? Number(props.prevAnswers) : props.questionData.limitMin);
    const handleChange = (event)=>{
        setValue(event.target.value);
      }

    const handleClick = (question,answer)=>{
        const backFlag = props.prevAnswers ? 1 : 0;
        props.storeSliderData(question,answer,backFlag);
    }

    const getDollar = (number) => {
        return number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    }

    const getValue = () =>{
        switch(props.questionData.unit)
        {
            case '$':
                if(value==props.questionData.limitMin)
                {
                    return props.questionData.textMin;
                }
                else if(value==props.questionData.limitMax)
                {
                    return props.questionData.textMax;
                }
                else{
                    let lower = Number(value).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      });
                    let upper = (Number(value)+Number(props.questionData.increment)).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      });
                    return lower + " to " + upper;
                }
                break;
            case 'years':
                if(value==props.questionData.limitMin)
                {
                    return props.questionData.textMin;
                }
                else if(value==props.questionData.limitMax)
                {
                    return props.questionData.textMax;
                }
                else{
                    return value + ' Years Ago';
                }
                break;
            default:
                break;
        }



    }

    return <div className="slider--slidecontainer">
        <h2>{props.questionData.question}</h2>
        <h1>{getValue()}</h1>
            <input 
            type="range" 
            min={props.questionData.limitMin}
            max={props.questionData.limitMax}
            value={value} 
            onChange={handleChange}
            step={props.questionData.increment}
            className="slider--slider"
            />
                <div className='slider--min--max'>
                    <p className='slider--min'>{props.questionData.unit === '$' ? getDollar(props.questionData.limitMin) : props.questionData.limitMin + " years"}</p>
                    <p className='slider--max'>{props.questionData.unit === '$' ? getDollar(props.questionData.limitMax) : props.questionData.limitMax + " years"}</p>
                </div>
                <div className={buttonClasses.root}>
                    <Button
                        className={buttonClasses.button}
                        variant="contained"
                        color="default"
                        onClick={()=>handleClick(props.questionData.json,value)}
                    >
                        {props.questionData.buttonText}
                    </Button>
                </div>
        </div>;
}

export default Slider;