import React from 'react'
import './style.css'
import logo from '../images/ESSENTIAL/LBlogo.svg'
import confVector from '../images/ESSENTIAL/confirmation.svg'
import ReactPixel from 'react-facebook-pixel';

const Confirmation = () => {
    ReactPixel.track('Lead');
    return <>
    <div className='confirmation-container'>
        <img className='logo' src={logo} alt=''/>
        <hr></hr>
        <h1>THANK YOU</h1>
        <h3>Your request has been received and is being processed. We'll contact you soon to calculate your payment</h3>
        <img className='confirmation-vector bounce-top' src={confVector} alt=''/>
        <p>For faster assistance please call: </p>
        <h2>(800) 777-6555</h2>
    </div>
    </>
}

export default Confirmation;